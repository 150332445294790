const serverConfigEnv = {
    /**
     * Should ssr requests be logged to "themanifest.json"
     * (this is only used in development to create the SSR-Request map)
     *
     * @type boolean
     */
    collectReduxRequests: false,

    /**
     * Should redux requests be prefetched for ssr
     * (this is used in production to make sure the server fetches all the data it needs)
     *
     * @type boolean
     */
    enableServerSideReduxFetching: true,

    /**
     * The url to our remote sitemap file provided by AFCORE
     *
     * @type string
     */
    sitemapUrl: process.env.SITEMAPURL,

    /**
     * The Caching server method used to do ssr caching
     * @type 'mysql' | 'mssql' | 'none' | 'blob'
     */
    cachingService: 'blob',

    /**
     * The account name (i.e: straccapprovalsysdev)
     * @type string
     */
    blobCacheAccount: process.env.BLOBCACHEACCOUNT,

    /**
     * The container name we are saving files into (i.e: afwebpwa-cache)
     * @type string
     */
    blobCacheContainerName: process.env.BLOBCACHECONTAINERNAME,

    /**
     * The securityToken (i.e: ?sp=racwdl&st=2021-02-15T10:03:24Z&se=2030-06-16T10:03:00Z&sv=2019-12-12&sr=c&sig=XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX)
     * @type string
     */
    blobCacheSecurityToken: process.env.BLOBCACHESECURITYTOKEN,
    
    /**
     * The account name (i.e: straccapprovalsysdev) for asset
     * @type string
     */
    assetBlobAccount: process.env.ASSETBLOBACCOUNT,

    /**
     * The container name we are saving files into (i.e: afwebpwa-cache) for asset
     * @type string
     */
    assetBlobContainerName: process.env.ASSETBLOBCONTAINERNAME,

    /**
     * The securityToken (i.e: ?sp...........XXXXXXXXXXXXXXXXXXXXXXXX) for asset
     * @type string
     */
    assetBlobSecurityToken: process.env.ASSETBLOBSECURITYTOKEN,

};

//  console.log('Config dev', serverConfigEnv);
export default serverConfigEnv;
