import * as React from 'react';
import * as styles from './PromotionworldTeaserItem.scss';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import { PromotionworldGridGroupPromotionImageType } from '../../types/IPromotionworldGridGroupType';
import Icon from '../../../Base/components/Icon/Icon';

interface IPromotionworldTeaserItemProps {
	promotionImage: PromotionworldGridGroupPromotionImageType;
	onPromotionClick: (promotion: PromotionworldGridGroupPromotionImageType) => void;
	directLinkClickouts: boolean;
}

class PromotionworldTeaserItem extends React.Component<IPromotionworldTeaserItemProps, {}> {
	public render () {
		const { promotionImage, onPromotionClick } = this.props;
		
		if (!promotionImage) {
			return (
				<span className={styles.PromotionworldTeasertemWrapper}></span>
			);
		}

		return (
			<a
				href={promotionImage?.clickoutUrl}
				target='_blank'
				className={styles.PromotionworldTeasertemWrapper}
				onClick={() => onPromotionClick(promotionImage)}
			>
				<span className={styles.ImageWrapper}>
					<ImageEnhanced source={promotionImage.image.resolutions} bgImage={false}/>
				</span>
				<Icon icon="hotspot" className={styles.PromotionworldTeasertemClickout} />
			</a>
		);
	}
}

export default PromotionworldTeaserItem;


